import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  itemsPerPage?: number;
  defaultSearchTerm: string;
  errorHandling?: GqlErrorHandlingOptions;
};
export default function useSubmissionFiltering(options?: Options) {
  const route = useRoute();
  const itemsPerPage = options?.itemsPerPage || 20;
  const _searchTerm = ref(options?.defaultSearchTerm || "");
  const queryClient = useQueryClient();
  const statusFilter = useSubmissionStatusUrlMap();

  const filterObject = useClaimFilterObject();
  async function queryFn({ pageParam = 0 }: { pageParam?: number }) {
    const res = await GqlFilterSubmission({
      limit: itemsPerPage + 1, // fetch one more item to check if there are more items to fetch
      offset: pageParam,
      search: `%${_searchTerm.value}%`,
      claimStatus: statusFilter.value,
      filter: filterObject.value
    });
    const hasMoreItems = res.data.length > itemsPerPage;
    console.log("queryFn hasMoreItems", hasMoreItems);
    const data = hasMoreItems ? res.data.slice(0, res.data.length - 1) : res.data;
    const nextCursor = hasMoreItems ? pageParam + itemsPerPage : undefined;
    return {
      data,
      nextCursor
    };
  }
  watch(filterObject, () => {
    refetch();
  });

  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    isError,
    refetch,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.submissions, route.params.filter, filterObject.value],
    queryFn: queryFn,
    getNextPageParam: (lastPage) => lastPage && lastPage.nextCursor,
    initialPageParam: 0
  });

  function onSearchTermChanged(term: string) {
    _searchTerm.value = term;
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.submissions, route.params.filter] });
  }

  const submissions = computed(() => {
    return data.value?.pages.flatMap((page) => page.data) || [];
  });
  useGqlErrorHandling(error, data, options?.errorHandling);

  return {
    submissions,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage: () => {
      // safe guard to prevent weired behavior
      if (hasNextPage) fetchNextPage();
    },
    onSearchTermChanged,
    searchTerm: readonly(_searchTerm)
  };
}
